import type {Pagination, RequestOptions} from 'Types/api';
import {ApiTarget} from 'Types/api';
import type {AccessToken} from 'Utility/authManager';
import authManager from 'Utility/authManager';
import {get as getSvelteStore} from 'svelte/store';
import {apiRequestBaseUrl, dealMakerUrl} from 'Store/InvestorFlowStore';
import {getEnvironmentVariable} from 'Utility/document';
import {kebabCase} from 'lodash';
import {parseFileName} from 'Utility/fetch/headers/contentDisposition';
import {sanitizeString} from 'Utility/formatters/sanitizeValue';

const getHeaderForTarget = (target: ApiTarget): string => {
  switch (target) {
    case ApiTarget.V1:
      return 'application/vnd.dealmaker-v1+json';
    case ApiTarget.Internal:
      return 'application/vnd.dealmaker-internal+json';
    case ApiTarget.EmbedV1:
      return 'application/vnd.dealmaker-embed_v1+json';
  }

  return '';
};

export const getUTMParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams: Record<string, string> = {};

  urlParams.forEach((value, key) => {
    if (key.startsWith('utm')) {
      utmParams[`X-DEALMAKER-${kebabCase(sanitizeString(key))}`.toUpperCase()] =
        sanitizeString(value);
    }
  });

  return utmParams;
};

const request = async (
  target: ApiTarget,
  input: string,
  init?: RequestInit,
  options?: RequestOptions
): Promise<Response> => {
  if (target === ApiTarget.Controller) {
    return controllerRequest(input, init);
  } else {
    return apiRequest(target, input, init, options);
  }
};

const apiRequest = async (
  target: ApiTarget,
  input: string,
  init?: RequestInit,
  options?: RequestOptions
): Promise<Response> => {
  const requestUrl = getSvelteStore(apiRequestBaseUrl);

  let baseUrl = requestUrl
    ? requestUrl
    : getEnvironmentVariable('SVELTE_APP_API_HOST');

  baseUrl = baseUrl.toString().endsWith('/') ? baseUrl : `${baseUrl}/`;

  const headers = {
    ...init?.headers,
    ...getUTMParameters(),
    Accept: getHeaderForTarget(target),
  };

  if (!options?.anonymous) {
    const accessToken: AccessToken = await authManager.getAccessToken();

    Object.assign(headers, {
      Authorization: `Bearer ${accessToken.access_token}`,
    });
  }

  return fetch(`${baseUrl}${input}`, {
    ...init,
    headers,
  });
};

const controllerRequest = async (
  input: string,
  init?: RequestInit
): Promise<Response> => {
  const requestUrl = getSvelteStore(dealMakerUrl);

  let baseUrl = requestUrl
    ? requestUrl
    : getEnvironmentVariable('SVELTE_APP_HOST');

  baseUrl = baseUrl.toString().endsWith('/') ? baseUrl : `${baseUrl}/`;

  return fetch(`${baseUrl}${input}`, {
    ...init,
    headers: {
      ...init.headers,
    },
  });
};

const get = (
  target: ApiTarget,
  input: string,
  init?: RequestInit,
  options?: RequestOptions
): Promise<Response> => {
  return request(target, input, {...init, method: 'GET'}, options);
};

const getJson = async <T>(
  target: ApiTarget,
  input: string,
  init?: RequestInit,
  options?: RequestOptions
): Promise<T> => {
  const response = await get(target, input, init, options);

  if (response?.ok) {
    const body = await response.json();

    return body as T;
  } else {
    throw new Error('Server Error');
  }
};

const getFile = async (
  target: ApiTarget,
  input: string,
  init?: RequestInit,
  options?: RequestOptions
): Promise<File> => {
  const response = await get(target, input, init, options);

  if (response?.ok) {
    const blob = await response.blob();

    const contentType = response.headers.get('content-type');

    return new File([blob], parseFileName(response.headers), {
      type: contentType,
    });
  } else {
    throw new Error('something went wrong');
  }
};

const post = (
  target: ApiTarget,
  input: string,
  init?: RequestInit,
  options?: RequestOptions
): Promise<Response> => {
  return request(target, input, {...init, method: 'POST'}, options);
};

const postJson = async <T>(
  target: ApiTarget,
  input: string,
  body?: object,
  init?: RequestInit,
  options?: RequestOptions
): Promise<T> => {
  const response = await post(
    target,
    input,
    {
      ...init,
      body: JSON.stringify(body),
      headers: {'Content-Type': 'application/json'},
    },
    options
  );

  if (response?.ok) {
    const body = await response.json();
    return body;
  } else {
    return processAndRaiseError(response);
  }
};

const put = (
  target: ApiTarget,
  input: string,
  init?: RequestInit,
  options?: RequestOptions
): Promise<Response> => {
  return request(target, input, {...init, method: 'PUT'}, options);
};

const patch = (
  target: ApiTarget,
  input: string,
  init?: RequestInit,
  options?: RequestOptions
): Promise<Response> => {
  return request(target, input, {...init, method: 'PATCH'}, options);
};

const deleteMethod = (
  target: ApiTarget,
  input: string,
  init?: RequestInit,
  options?: RequestOptions
): Promise<Response> => {
  return request(target, input, {...init, method: 'DELETE'}, options);
};

const patchJson = async <T>(
  target: ApiTarget,
  input: string,
  body?: object,
  init?: RequestInit,
  options?: RequestOptions
): Promise<T> => {
  const response = await patch(
    target,
    input,
    {
      ...init,
      body: JSON.stringify(body),
      headers: {
        ...init?.headers,
        'Content-Type': 'application/json',
      },
    },
    options
  );

  if (response?.ok) {
    const body = await response.json();

    return body;
  } else {
    return processAndRaiseError(response);
  }
};

const putJson = async <T>(
  target: ApiTarget,
  input: string,
  init?: RequestInit,
  options?: RequestOptions
): Promise<T> => {
  const response = await put(target, input, init, options);

  if (response?.ok) {
    const body = await response.json();

    return body;
  } else {
    return processAndRaiseError(response);
  }
};

const processAndRaiseError = async (response: Response) => {
  const body = await response.json();
  const error = body?.error;
  throw new Error(error?.message || error || 'Server Error');
};

const extractPagination = (response: Response): Pagination => {
  const {headers} = response;

  const getHeaderValue = (header: string) => {
    if (!headers.has(header)) return undefined;

    const headerValue = headers.get(header);
    if (isNaN(Number(headerValue))) return undefined;

    return parseInt(headerValue);
  };

  const pagination = {
    page: getHeaderValue('x-page'),
    perPage: getHeaderValue('x-per-page'),
    total: getHeaderValue('x-total'),
    totalPages: getHeaderValue('x-total-pages'),
  };

  return Object.fromEntries(
    Object.entries(pagination).filter(
      ([, value]) => value !== undefined && value !== null
    )
  );
};

export enum HttpStatusCodes {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

const create = () => {
  return {
    request,
    get,
    getJson,
    getFile,
    post,
    postJson,
    put,
    putJson,
    patch,
    patchJson,
    delete: deleteMethod,
    extractPagination,
  };
};

export default create();
