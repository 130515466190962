import {ApiTarget} from 'Types/api';
import api from 'Utility/api';
import {writable} from 'svelte/store';

export const environmentVariables = writable<Record<string, string>>({});

type Variables = {
  SVELTE_APP_API_HOST: string;
  SVELTE_APP_STRIPE_PUBLIC_KEY: string;
  SVELTE_APP_AMPLITUDE_CLIENT_KEY: string;
  SVELTE_APP_HOST: string;
  SVELTE_APP_DATADOG_RUM_APPLICATION_ID: string;
  SVELTE_APP_DATADOG_RUM_CLIENT_TOKEN: string;
  SVELTE_APP_DATADOG_TOKEN: string;
  STATIC_PAGE_TERMS_AND_CONDITIONS: string;
  public_domains: string;
  dropbox_sign_skip_domain_verification: string;
  recaptcha_threshold: string;
  google_geocoder_api_key: string;
};

type AppSettingsResponse = {
  variables: Variables;
};

export const loadAppSettings = async (): Promise<void> => {
  const variables = await getAppSettings();
  environmentVariables.set(variables);
};

const getAppSettings = async (): Promise<Variables> => {
  const response = await api.getJson<AppSettingsResponse>(
    ApiTarget.V1,
    'app_settings'
  );

  return response.variables;
};
